/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(".mobile-menu-toggle").click(function(e){
            $(".wrapper").toggleClass("animate");
            $(".mobile-menu-container").toggleClass("animate");

            e.preventDefault();
            
        });


        $("div.wrapper").click(function(e){

              if( $(".mobile-menu-container").hasClass("animate") ){
                 $(".wrapper").removeClass("animate");
                 $(".mobile-menu-container").removeClass("animate");
              }


              
        });


        
        $('#latestProjects').slick({
          prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-angle-left fa-2x'></span></a>",
          nextArrow: "<a href='#' class='slick-next'><span class='fa fa-angle-right fa-2x'></span></a>",
          speed: 600,


        });



        $(window).scroll( function( e ){ 
            if( $(this).scrollTop() > 5 ){
                $(".logo").addClass("animate");
            } else {
                $(".logo").removeClass("animate");
            }
        });

        $(window).scroll( function( e ){ 
            if( $(this).scrollTop() > 5 ){
                $("a.back-top").addClass("animate");
            } else {
                $("a.back-top").removeClass("animate");
            }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        /* $('#recentProjects').on('init', function(event, slick){
           var $items = slick.$dots.find('li');
           $items.addClass('transparent-circle');
           $items.find('button').remove();
        }); */

        $('#recentProjects').slick({
          prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-angle-left fa-2x'></span></a>",
          nextArrow: "<a href='#' class='slick-next'><span class='fa fa-angle-right fa-2x'></span></a>",
          speed: 600,


        });

        $('#hero-banner').slick({
          prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-angle-left fa-2x'></span></a>",
          nextArrow: "<a href='#' class='slick-next'><span class='fa fa-angle-right fa-2x'></span></a>",
          speed: 900,
          autoplay: true,
          autoplaySpeed: 4500,
          fade: true,
          infinite: true,
          cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
          touchThreshold: 100


        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $(".projects-beforeafter").twentytwenty();

        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'projects': {
        init: function() {

        },
        finalize: function() {
          $(".projects-beforeafter").twentytwenty();
        }
    },
    'single_projects': {
        init: function() {

        },
        finalize: function() {
         $(".projects-beforeafter-single").twentytwenty();
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
